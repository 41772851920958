// Body
$body_bg: #d6d3d7;
$body_bg_full: #258bca;
$nav_bg : #004b93;

$dark_blue: #22296b;
$blue: #3c30ba;
$light_blue: #7ba9da;
$light_coral: #f08080;
$light_gray: #e1dee3;
$gray: #a5a7ae;
$accent: #a51553;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;
 