// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-icons/font/bootstrap-icons';

// Datepicker dataTable bs5
@import "~datatables.net-bs5/css/dataTables.bootstrap5.css";

// Dropzone
@import '~dropzone/dist/dropzone.css';



body {
    background-color: $body_bg_full;

    nav {
        background-color: $nav_bg;

        a.navbar-brand, a.navbar-brand:hover, a.navbar-brand:visited, a.navbar-brand:link {
            color: $accent;
            font-weight: bold;
            font-size: 1.4rem;
        }
       
        a, .nav-link {
            color: $white;
        }

        a:hover, a:active, a:visited, a:link,
        .nav-link:hover, .nav-link:active, .nav-link:visited, .nav-link:link {
            color: $white;
        }

        #logo {
            width: 100px;
            filter: contrast(200%);
        }
        .nav-link  {
            font-size: 1.1em;
            font-weight: bold !important;
        }
        .nav-active {
            background-color: rgba(0,0,0,0.07);
            border-radius: 5px;
            font-weight: bold !important;
        }
    }
    .dropdown-menu {
        background-color: $nav_bg;

        .dropdown-item:hover {
            color: $dark_blue;
            background-color: $gray;
        }

        .dropdown-item.active, .dropdown-item:active {
            color: $white;
            background-color: $gray;
            font-weight:bold;
        }
    }

    .card {
        border-color: transparent !important;
        border: 0;
        background-color: $light_gray;

        .card-header {
            background-color: $accent;
            color: $light_gray;

            h4 {
                margin: 5px 0;
            }
        }
    }

    .badge {
        font-size: 1em !important;
    }

    select[readonly], input[readonly] {
        pointer-events: none;
        touch-action: none;
        background-color: $light_gray !important;
    }
    
    // dropzone
    #file.dropzone, #files-show.dropzone-show, #commnets-show {
        min-height: 58px !important;
        background-color: $body_bg !important;
        border: 1px solid #ced4da !important;
    }
    #files-show.dropzone-show, #commnets-show {
        background-color: $light_gray !important;
    }
    #files-show.dropzone-show  .file-show, #commnets-show .comments {
        margin: 25px 0 5px 0;
        padding: 0 12px;
    }
    #file.dropzone .dz-message {
        margin: 0 !important;
    }
    #file.dropzone .dz-message .dz-button {
        padding: 0.25rem 0.5rem;
        font-size: 0.7875rem;
        border-radius: 0.2rem;
        background-color: $white;
    }
    #file.dropzone .dz-message .dz-button:hover {
        background-color: $light_gray;
    }
    #file.dropzone .dz-preview {
        margin: 10px 5px  !important;
    }

    #files-dropzone-form, #files-dropzone-show, #commnets-show {
        position: relative;
    }

    #files-dropzone-form > label, #files-dropzone-show > label, #commnets-show > label {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        padding: 0.5rem 0.75rem;
        pointer-events: none;
        border: 1px solid transparent;
        opacity: 0.65;
        font-size: 12px;
    }
    #commnets-show .commnet-box {

        .my-name {
            margin-left: 10px;
        }
        .response-name {
            margin-right: 10px;
            text-align: right;
        }

        .message {
            margin: 10px 0;
            padding: 5px 10px;
            line-height: 22px;
            font-size: 14px;
            border-radius: 7px;
            display: inline-block;
            position: relative;

            .small-commnet {
                color: $white;
                font-size: 10px;
                line-height: 12px;
                margin-top: 5px;
                text-align: right;
            }
        }
        .my-message {
            background-color: $light_blue;
            float: left;
        }
        .response-message {
            background-color: $light_coral;
            float: right;
            
        }
        .my-message::after {
            bottom: 100%;
            left: 30px;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-bottom-color: $light_blue;
            border-width: 10px;
            margin-left: -10px;
        }
        .response-message::after {
            bottom: 100%;
            right: 30px;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-bottom-color: $light_coral;
            border-width: 10px;
            margin-left: -10px;
            
        }
    }

    .overlay {
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        position: fixed;
        background: rgba(0,0,0,0.5);
        z-index: 1000000;

        .overlay__inner {
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            position: absolute;
    
            .overlay__content {
                left: 50%;
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);
                z-index: 1000000;
                display: block;
                text-align: center;
            }
    
            .spinner {
                width: 75px;
                height: 75px;
                display: inline-block;
                border-width: 2px;
                border-color: $gray;
                border-top-color: $white;
                animation: spin 1s infinite linear;
                border-radius: 100%;
                border-style: solid;
            }
    
            @keyframes spin {
                100% {
                  transform: rotate(360deg);
                }
            }
        }
    }
    
    
    .shadow-sm {
        box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.3)!important;
    }


    // footer 
    footer {
        position: fixed;
        bottom: 0;
        width: 100%;
        border-top: 2px solid $nav_bg;
        color: $gray;
        z-index: 1000;
        background-color: $nav_bg;
    }

    
}
